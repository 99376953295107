<template>
    <b-row>
        <b-col sm="12">
            <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                <b-form @submit.prevent="handleSubmit(submit)" @reset.prevent="reset">
                    <b-overlay :show="unitLoad">
                        <div class="application-form-wrapper">
                            <div class="application-itmes">
                                <b-row>
                                    <b-col sm="12">
                                        <div class="jumbotron" style="padding: 1rem">
                                            <p><b class="text-alt">{{ $t('exportTrophyCircular.circular') }} :</b> <span class="lead">{{ circular_name }}</span></p>
                                        </div>
                                    </b-col>
                                </b-row>
                                <b-row>
                                    <b-col sm="12">
                                        <div class="group-form-card">
                                            <b-card>
                                                <b-form-group label-cols-lg="3" :label="$t('externalTradeFair.company_basic_info')" label-size="lg"
                                                    label-class="font-weight-bold pt-0" class="mb-0">
                                                    <b-row>
                                                        <b-col sm="6">
                                                            <ValidationProvider name="Name (En)" vid="name_en" rules="required" v-slot="{ errors }">
                                                                <b-form-group label-for="name_en">
                                                                    <template v-slot:label>
                                                                        {{ $t('globalTrans.applicant_name') + ' ' + $t('globalTrans.en') }} <span class="text-danger">*</span>
                                                                    </template>
                                                                    <b-form-input v-model="application.name_en" :state="errors[0] ? false : (valid ? true : null)"></b-form-input>
                                                                    <div class="invalid-feedback">
                                                                        {{ errors[0] }}
                                                                    </div>
                                                                </b-form-group>
                                                            </ValidationProvider>
                                                        </b-col>
                                                        <b-col sm="6">
                                                            <ValidationProvider name="Name (Bn)" vid="name_bn" v-slot="{ errors }">
                                                                <b-form-group label-for="name_bn">
                                                                    <template v-slot:label>
                                                                        {{ $t('globalTrans.applicant_name') + ' ' + $t('globalTrans.bn') }}
                                                                    </template>
                                                                    <b-form-input v-model="application.name_bn"
                                                                        :state="errors[0] ? false : (valid ? true : null)"></b-form-input>
                                                                    <div class="invalid-feedback">
                                                                        {{ errors[0] }}
                                                                    </div>
                                                                </b-form-group>
                                                            </ValidationProvider>
                                                        </b-col>
                                                        <b-col sm="6">
                                                            <ValidationProvider name="Designation (En)" vid="designation_en" rules="required" v-slot="{ errors }">
                                                                <b-form-group label-for="designation_en">
                                                                    <template v-slot:label>
                                                                        {{ $t('globalTrans.designation') + ' ' + $t('globalTrans.en') }} <span class="text-danger">*</span>
                                                                    </template>
                                                                    <b-form-input v-model="application.designation_en"
                                                                        :state="errors[0] ? false : (valid ? true : null)"></b-form-input>
                                                                    <div class="invalid-feedback">
                                                                        {{ errors[0] }}
                                                                    </div>
                                                                </b-form-group>
                                                            </ValidationProvider>
                                                        </b-col>
                                                        <b-col sm="6">
                                                            <ValidationProvider name="Designation (Bn)" vid="designation_bn" v-slot="{ errors }">
                                                                <b-form-group label-for="designation_bn">
                                                                    <template v-slot:label>
                                                                        {{ $t('globalTrans.designation') + ' ' + $t('globalTrans.bn') }}
                                                                    </template>
                                                                    <b-form-input v-model="application.designation_bn"
                                                                        :state="errors[0] ? false : (valid ? true : null)"></b-form-input>
                                                                    <div class="invalid-feedback">
                                                                        {{ errors[0] }}
                                                                    </div>
                                                                </b-form-group>
                                                            </ValidationProvider>
                                                        </b-col>
                                                    </b-row>
                                                </b-form-group>
                                                <b-form-group label-cols-lg="3" :label="$t('externalTradeFair.org_info')" label-size="lg"
                                                    label-class="font-weight-bold pt-0" class="mb-0 mt-4">
                                                    <b-row>
                                                        <b-col sm="6">
                                                            <ValidationProvider name="Organization Name (En)" vid="comp_name_en" rules="required" v-slot="{ errors }">
                                                                <b-form-group label-for="comp_name_en">
                                                                    <template v-slot:label>
                                                                        {{ $t('externalTradeFair.org_comp_name') + '( ' + $t('externalTradeFair.as_trade_license') + ') ' + $t('globalTrans.en') }} <span class="text-danger">*</span>
                                                                    </template>
                                                                    <b-form-input v-model="application.comp_name_en" :state="errors[0] ? false : (valid ? true : null)"></b-form-input>
                                                                    <div class="invalid-feedback">
                                                                        {{ errors[0] }}
                                                                    </div>
                                                                </b-form-group>
                                                            </ValidationProvider>
                                                        </b-col>
                                                        <b-col sm="6">
                                                            <ValidationProvider name="Organization Name (Bn)" vid="comp_name_bn" rules="required" v-slot="{ errors }">
                                                                <b-form-group label-for="comp_name_bn">
                                                                    <template v-slot:label>
                                                                        {{ $t('externalTradeFair.org_comp_name') + '( ' + $t('externalTradeFair.as_trade_license') + ') ' + $t('globalTrans.bn') }} <span class="text-danger">*</span>
                                                                    </template>
                                                                    <b-form-input v-model="application.comp_name_bn"
                                                                        :state="errors[0] ? false : (valid ? true : null)"></b-form-input>
                                                                    <div class="invalid-feedback">
                                                                        {{ errors[0] }}
                                                                    </div>
                                                                </b-form-group>
                                                            </ValidationProvider>
                                                        </b-col>
                                                        <b-col sm="6">
                                                            <ValidationProvider name="Telephone No." vid="phone_no" rules="max:11" v-slot="{ errors }">
                                                                <b-form-group label-for="phone_no">
                                                                    <template v-slot:label>
                                                                        {{ $t('externalUser.telephone_no') }}
                                                                    </template>
                                                                    <b-form-input v-model="application.phone_no" :state="errors[0] ? false : (valid ? true : null)"
                                                                        oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"></b-form-input>
                                                                    <div class="invalid-feedback">
                                                                        {{ errors[0] }}
                                                                    </div>
                                                                </b-form-group>
                                                            </ValidationProvider>
                                                        </b-col>
                                                        <b-col sm="6">
                                                            <ValidationProvider name="Mobile No." vid="mobile_no" rules="required|max:11|min:11" v-slot="{ errors }">
                                                                <b-form-group label-for="mobile_no">
                                                                    <template v-slot:label>
                                                                        {{ $t('globalTrans.mobile_no') }} <span
                                                                            class="text-danger">*</span>
                                                                    </template>
                                                                    <b-form-input v-model="application.mobile_no" :state="errors[0] ? false : (valid ? true : null)"
                                                                        oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"></b-form-input>
                                                                    <div class="invalid-feedback">
                                                                        {{ errors[0] }}
                                                                    </div>
                                                                </b-form-group>
                                                            </ValidationProvider>
                                                        </b-col>
                                                        <b-col sm="6">
                                                            <ValidationProvider name="Email" vid="email" rules="email" v-slot="{ errors }">
                                                                <b-form-group label-for="email">
                                                                    <template v-slot:label>
                                                                        {{ $t('globalTrans.email') }}
                                                                    </template>
                                                                    <b-form-input id="org_bn" type="email" v-model="application.email"
                                                                        :state="errors[0] ? false : (valid ? true : null)"></b-form-input>
                                                                    <div class="invalid-feedback">
                                                                        {{ errors[0] }}
                                                                    </div>
                                                                </b-form-group>
                                                            </ValidationProvider>
                                                        </b-col>
                                                        <b-col sm="6">
                                                            <ValidationProvider name="Fax" vid="fax" rules="" v-slot="{ errors }">
                                                                <b-form-group label-for="fax">
                                                                    <template v-slot:label>
                                                                        {{ $t('globalTrans.fax') }}
                                                                    </template>
                                                                    <b-form-input v-model="application.fax" :state="errors[0] ? false : (valid ? true : null)"
                                                                        oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');">
                                                                    </b-form-input>
                                                                    <div class="invalid-feedback">
                                                                        {{ errors[0] }}
                                                                    </div>
                                                                </b-form-group>
                                                            </ValidationProvider>
                                                        </b-col>
                                                    </b-row>
                                                </b-form-group>
                                            </b-card>
                                        </div>
                                    </b-col>
                                    <b-col sm="12">
                                        <div class="group-form-card">
                                            <b-card>
                                                <b-form-group label-cols-lg="3" :label="$t('globalTrans.org_address')" label-size="lg"
                                                    label-class="font-weight-bold pt-0" class="mb-0">
                                                    <b-row>
                                                        <b-col sm="6">
                                                            <ValidationProvider name="Country Type" vid="country_type" rules="required|min_value:1">
                                                                <b-form-group label-for="country_type" label-cols-sm="4" slot-scope="{ valid, errors }">
                                                                    <template v-slot:label>
                                                                        {{ $t('externalTradeFair.country_type') }}
                                                                    </template>
                                                                    <b-form-radio-group v-model="address.country_type"
                                                                        :options="countryTypeList"
                                                                        @change="countryAction()"
                                                                        :disabled="application.circular_type == 1 ? false : true"
                                                                        value-field="value" text-field="text"
                                                                        :state="errors[0] ? false : (valid ? true : null)"></b-form-radio-group>
                                                                    <div class="invalid-feedback d-block">
                                                                        {{ errors[0] }}
                                                                    </div>
                                                                </b-form-group>
                                                            </ValidationProvider>
                                                        </b-col>
                                                        <b-col v-if="address.country_type == 2" sm="6">
                                                            <ValidationProvider name="Country" vid="country_id" rules="required|min_value:1" v-slot="{ errors }">
                                                                <b-form-group label-for="country_id" label-cols-sm="4">
                                                                <template v-slot:label>
                                                                    {{$t('globalTrans.country')}} <span class="text-danger">*</span>
                                                                </template>
                                                                <b-form-select
                                                                    plain
                                                                    v-model="address.country_id"
                                                                    :options="countryList"
                                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                                    >
                                                                    <template v-slot:first>
                                                                        <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                                    </template>
                                                                </b-form-select>
                                                                <div class="invalid-feedback">
                                                                    {{ errors[0] }}
                                                                </div>
                                                                </b-form-group>
                                                            </ValidationProvider>
                                                        </b-col>
                                                    </b-row>
                                                    <b-row v-if="address.country_type == 2">
                                                        <b-col sm="6">
                                                            <ValidationProvider name="State (En)" vid="state_en" rules="required" v-slot="{ errors }">
                                                                <b-form-group label-for="state_en" label-cols-sm="4">
                                                                    <template v-slot:label>
                                                                        {{ $t('externalTradeFair.state') + ' ' + $t('globalTrans.en') }} <span class="text-danger">*</span>
                                                                    </template>
                                                                    <b-form-input v-model="address.state_en" :state="errors[0] ? false : (valid ? true : null)"></b-form-input>
                                                                    <div class="invalid-feedback">
                                                                        {{ errors[0] }}
                                                                    </div>
                                                                </b-form-group>
                                                            </ValidationProvider>
                                                        </b-col>
                                                        <b-col sm="6">
                                                            <ValidationProvider name="State (Bn)" vid="state_bn" v-slot="{ errors }">
                                                                <b-form-group label-for="state_bn" label-cols-sm="4">
                                                                    <template v-slot:label>
                                                                        {{ $t('externalTradeFair.state') + ' ' + $t('globalTrans.bn') }}
                                                                    </template>
                                                                    <b-form-input v-model="address.state_bn" :state="errors[0] ? false : (valid ? true : null)"></b-form-input>
                                                                    <div class="invalid-feedback">
                                                                        {{ errors[0] }}
                                                                    </div>
                                                                </b-form-group>
                                                            </ValidationProvider>
                                                        </b-col>
                                                        <b-col sm="6">
                                                            <ValidationProvider name="City (En)" vid="city_en" rules="required" v-slot="{ errors }">
                                                                <b-form-group label-for="city_en" label-cols-sm="4">
                                                                    <template v-slot:label>
                                                                        {{ $t('externalTradeFair.city') + ' ' + $t('globalTrans.en') }} <span class="text-danger">*</span>
                                                                    </template>
                                                                    <b-form-input v-model="address.city_en" :state="errors[0] ? false : (valid ? true : null)"></b-form-input>
                                                                    <div class="invalid-feedback">
                                                                        {{ errors[0] }}
                                                                    </div>
                                                                </b-form-group>
                                                            </ValidationProvider>
                                                        </b-col>
                                                        <b-col sm="6">
                                                            <ValidationProvider name="City (Bn)" vid="city_bn" v-slot="{ errors }">
                                                                <b-form-group label-for="city_bn" label-cols-sm="4">
                                                                    <template v-slot:label>
                                                                        {{ $t('externalTradeFair.city') + ' ' + $t('globalTrans.bn') }}
                                                                    </template>
                                                                    <b-form-input v-model="address.city_bn" :state="errors[0] ? false : (valid ? true : null)"></b-form-input>
                                                                    <div class="invalid-feedback">
                                                                        {{ errors[0] }}
                                                                    </div>
                                                                </b-form-group>
                                                            </ValidationProvider>
                                                        </b-col>
                                                        <b-col sm="6">
                                                            <ValidationProvider name="Address Details (En)" vid="address_details_en" rules="max:255" v-slot="{ errors }">
                                                                <b-form-group label-for="address_details_en" label-cols-sm="4">
                                                                    <template v-slot:label>
                                                                        {{ $t('externalUser.address_details') + ' ' + $t('globalTrans.en') }}
                                                                    </template>
                                                                    <b-form-textarea
                                                                        v-model="address.address_details_en"
                                                                        rows="1"
                                                                        max-rows="2"
                                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                                    ></b-form-textarea>
                                                                    <div class="invalid-feedback">
                                                                        {{ errors[0] }}
                                                                    </div>
                                                                </b-form-group>
                                                            </ValidationProvider>
                                                        </b-col>
                                                        <b-col sm="6">
                                                            <ValidationProvider name="Address Details (Bn)" vid="address_details_bn" rules="max:255" v-slot="{ errors }">
                                                                <b-form-group label-for="address_details_bn" label-cols-sm="4">
                                                                    <template v-slot:label>
                                                                        {{ $t('externalUser.address_details') + ' ' + $t('globalTrans.bn') }}
                                                                    </template>
                                                                    <b-form-textarea
                                                                        v-model="address.address_details_bn"
                                                                        rows="1"
                                                                        max-rows="2"
                                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                                    ></b-form-textarea>
                                                                    <div class="invalid-feedback">
                                                                        {{ errors[0] }}
                                                                    </div>
                                                                </b-form-group>
                                                            </ValidationProvider>
                                                        </b-col>
                                                        <b-col sm="6">
                                                            <ValidationProvider name="Post Code" vid="post_code" rules="" v-slot="{ errors }">
                                                                <b-form-group label-for="post_code" label-cols-sm="4">
                                                                    <template v-slot:label>
                                                                        {{ $t('externalUser.postal_code') }}
                                                                    </template>
                                                                    <b-form-input
                                                                    v-model="address.post_code"
                                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                                    oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                                                                    >
                                                                    </b-form-input>
                                                                    <div class="invalid-feedback">
                                                                        {{ errors[0] }}
                                                                    </div>
                                                                </b-form-group>
                                                            </ValidationProvider>
                                                        </b-col>
                                                    </b-row>
                                                    <b-row v-else>
                                                        <b-col>
                                                            <Address v-if="comp_load" :data="address" :addressType="'address'" :param="fieldObj" @address-data="receiveAddress" title="Address"/>
                                                            <b-row v-else>
                                                                <b-col sm="6" md="6" class="mt-2">
                                                                    <b-skeleton height="50px" animation="wave" width="100%"></b-skeleton>
                                                                </b-col>
                                                                <b-col sm="6" md="6" class="mt-2">
                                                                    <b-skeleton height="50px" animation="wave" width="100%"></b-skeleton>
                                                                </b-col>
                                                                <b-col sm="6" md="6" class="mt-4">
                                                                    <b-skeleton height="50px" animation="wave" width="100%"></b-skeleton>
                                                                </b-col>
                                                                <b-col sm="6" md="6" class="mt-4">
                                                                    <b-skeleton height="50px" animation="wave" width="100%"></b-skeleton>
                                                                </b-col>
                                                                <b-col sm="6" md="6" class="mt-4">
                                                                    <b-skeleton height="50px" animation="wave" width="100%"></b-skeleton>
                                                                </b-col>
                                                                <b-col sm="6" md="6" class="mt-4">
                                                                    <b-skeleton height="50px" animation="wave" width="100%"></b-skeleton>
                                                                </b-col>
                                                                <b-col sm="6" md="6" class="mt-4">
                                                                    <b-skeleton height="50px" animation="wave" width="100%"></b-skeleton>
                                                                </b-col>
                                                                <b-col sm="6" md="6" class="mt-4">
                                                                    <b-skeleton height="50px" animation="wave" width="100%"></b-skeleton>
                                                                </b-col>
                                                            </b-row>
                                                        </b-col>
                                                    </b-row>
                                                </b-form-group>
                                            </b-card>
                                        </div>
                                    </b-col>
                                </b-row>
                            </div>
                        </div>
                    </b-overlay>
                </b-form>
            </ValidationObserver>
        </b-col>
    </b-row>
</template>
<script>
import RestApi, { internationalTradeFairServiceBaseUrl } from '@/config/api_config'
import { ditfTabOneStoreApi } from '../../../api/routes'
import { mapGetters } from 'vuex'
import Address from '@/components/Address.vue'

export default {
    props: ['app_id', 'application', 'app_address', 'circular'],
    components: { Address },
    data () {
        return {
            fieldObj: {
                xl: 6,
                lg: 6,
                md: 6,
                sm: 12,
                labelCols: 12,
                hasPostCode: true,
                isPostCodeNullable: true,
                noMargin: true
            },
            errors: [],
            valid: null,
            unitLoad: false,
            circular_name: '',
            comp_load: true,
            address: {},
            org_address: {}
        }
    },
    created () {
        // if (this.$route.query.id) {
        this.address = JSON.parse(JSON.stringify(this.app_address))
        // }
        if (this.address.country_type === 1) {
            this.address.country_id = 1
        }
        this.application.name_en = this.commonProfile.name
        this.application.name_bn = this.commonProfile.name_bn
        this.circular_name = this.$i18n.locale === 'bn' ? this.circular.title_bn : this.circular.title_en
    },
    watch: {
        currentLocale: function (newVal, oldVal) {
            if (newVal !== oldVal) {
                this.circular_name = this.$i18n.locale === 'bn' ? this.circular.title_bn : this.circular.title_en
            }
        }
    },
    computed: {
        countryList: function () {
            return this.$store.state.CommonService.commonObj.countryList.filter(item => item.status === 1).map(el => {
                return Object.assign({}, el, { text: this.$i18n.locale === 'bn' ? el.text_bn : el.text_en })
            })
        },
        countryTypeList () {
            return this.$store.state.TradeFairService.ditfCommonObj.countryTypeList.map(el => {
                return Object.assign({}, el, { text: this.$i18n.locale === 'en' ? el.text_en : el.text_bn })
            })
        },
        ...mapGetters({
            commonProfile: 'Auth/commonProfile',
            authUser: 'Auth/authUser'
        }),
        currentLocale () {
            return this.$i18n.locale
        }
    },
    methods: {
        countryAction () {
            this.comp_load = false
            if (this.address.country_type === 1) {
                this.address.country_id = 1
            } else {
                this.address.country_id = 0
            }
            if (!this.comp_load) {
                setTimeout(() => {
                this.comp_load = true
                }, 500) // 1.2 sec delay
            }
        },
        receiveAddress (addr) {
          this.org_address = addr
        },
        async submit () {
            var check = await this.$refs.form.validate()
            if (check) {
                this.unitLoad = true
                this.$store.dispatch('mutateCommonProperties', { loading: true })
                const loadingState = { loading: false, listReload: false }
                this.application.app_id = this.app_id
                if (this.address.country_type === 1) {
                    this.application.address = this.org_address
                    this.application.address.country_type = 1
                } else {
                    this.application.address = this.address
                }
                this.$emit('countryType', this.application.address.country_type)
                // this.application.address = this.address.country_type === 1 ? this.org_address : this.address
                const result = await RestApi.postData(internationalTradeFairServiceBaseUrl, ditfTabOneStoreApi, this.application)
                loadingState.listReload = true
                this.$store.dispatch('mutateCommonProperties', loadingState)
                this.unitLoad = false
                if (result.success) {
                    this.$toast.success({
                        title: 'Success',
                        message: this.app_id ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save_msg'),
                        color: '#D6E09B'
                    })
                    return result
                } else {
                    this.$toast.error({
                        title: this.$t('globalTrans.error'),
                        message: result.message,
                        color: '#ee5253'
                    })
                    this.$refs.form.setErrors(result.errors)
                }
            }
        }
    }
}
</script>
